import AOS from "aos";
import "aos/dist/aos.css";

const body = document.querySelector("body");
const menuToggle = document.getElementById("menu-toggle");
const menu = document.getElementById("mobile-menu");
const firstMenuItem = document.getElementById("first-menu-item");

window.addEventListener("load", function() {
  const form = document.getElementById("waitlist-form");
  form.addEventListener("submit", function(e) {
    const input = document.getElementById("email-input")
    input.style.display = "none";
    document.getElementById("waitlist-button").style.display = "none";
    document.getElementById("waitlisted-button").removeAttribute("hidden");
    e.preventDefault();
    const data = new FormData(form);
    const action = e.target.action;
    fetch(action, {
      method: 'POST',
      body: data,
    })
    .then(() => {
      //alert("Success! You've been added to the waitlist.");
    })
  });
});

const openMenu = () => {
  body.classList.add("disable-scroll");
  menu.classList.add("active");
  firstMenuItem.focus();
};

const closeMenu = () => {
  body.classList.remove("disable-scroll");
  menu.classList.remove("active");
  menuToggle.focus();
};

const toggleMenu = () => {
  if (menu.classList.contains("active")) {
    closeMenu();
  } else {
    openMenu();
  }
};

AOS.init({
  duration: 500,
});
//menuToggle.addEventListener("click", toggleMenu);
